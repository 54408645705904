









































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { GuiModule } from '../store/modules/gui';
import { defaultWaveform } from '../service/audio';
import { Waveform } from '../service/types';
import { ResizeObserver } from '@juggle/resize-observer';
import gsap from 'gsap';

@Component
export default class WaveformCanvas extends Vue {
  @Ref() readonly canvas!: HTMLCanvasElement;
  @Ref() readonly backgroundCanvas!: HTMLCanvasElement;

  @Prop({ type: Number, default: 0 })
  percent!: number;

  @Prop({ type: Boolean, default: true })
  lazy!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  draggingPercent: number = 0;
  dragging: boolean = false;

  resizeObserver: ResizeObserver | null = null;

  get waveform(): Waveform {
    const gui = getModule(GuiModule, this.$store);

    return gui.waveform ?? defaultWaveform(8, 800, 100);
  }

  get darkTheme() {
    return this.$vuetify.theme.dark;
  }

  get showPercent() {
    if (this.dragging) {
      return this.draggingPercent;
    }
    return this.percent;
  }

  @Watch('waveform')
  onWaveformChanged() {
    this.redraw();
  }

  @Watch('darkTheme')
  onThemeChanged() {
    this.redraw();
  }

  dragStart(event: MouseEvent | TouchEvent) {
    this.dragging = true;

    if (event instanceof MouseEvent) {
      this.draggingPercent = this.getPercentFromClientX(event.clientX);
    } else {
      this.draggingPercent = this.getPercentFromClientX(
        event.touches[0]?.clientX ?? 0
      );
    }

    if (!this.lazy) {
      this.$emit('update:percent', this.draggingPercent);
    }
  }

  dragMove(event: MouseEvent | TouchEvent) {
    if (!this.dragging) {
      return;
    }

    if (event instanceof MouseEvent) {
      this.draggingPercent = this.getPercentFromClientX(event.clientX);
    } else {
      this.draggingPercent = this.getPercentFromClientX(
        event.touches[0]?.clientX ?? 0
      );
    }

    if (!this.lazy) {
      this.$emit('update:percent', this.draggingPercent);
    }
  }

  dragStop(ent: MouseEvent | TouchEvent) {
    if (!this.dragging) {
      return;
    }

    this.dragging = false;
    this.$emit('update:percent', this.draggingPercent);
  }

  getPercentFromClientX(clientX: number): number {
    const progressRect = this.$el.getBoundingClientRect();
    const posPx = clientX - progressRect.left;
    const percent = posPx / progressRect.width;

    return Math.max(Math.min(percent, 1), 0);
  }

  autoResize() {
    let ignore = false;

    this.resizeObserver = new ResizeObserver((entries, observer) => {
      if (ignore) {
        ignore = false;
        return;
      }

      for (const entry of entries) {
        const { left, top, width, height } = entry.contentRect;
        this.resizeCanvas();
        ignore = true;
        return;
      }
    });

    this.resizeObserver.observe(this.$el);
  }

  resizeCanvas() {
    const dpr = window.devicePixelRatio || 1;

    this.canvas.height = this.$el.clientHeight * dpr;
    this.canvas.width = this.$el.clientWidth * dpr;
    this.backgroundCanvas.height = this.$el.clientHeight * dpr;
    this.backgroundCanvas.width = this.$el.clientWidth * dpr;

    this.draw();
  }

  async redraw() {
    await new Promise((resolve, reject) => {
      gsap.to(this.$el, {
        duration: 0.3,
        opacity: 0,
        onComplete: resolve
      });
    });

    this.draw();

    await new Promise((resolve, reject) => {
      gsap.to(this.$el, {
        duration: 0.3,
        opacity: 1,
        onComplete: resolve
      });
    });
  }

  draw() {
    let ctx = this.backgroundCanvas.getContext('2d')!;

    const primaryColor = this.$vuetify.theme.currentTheme.primary as string;
    const secondaryColor = 'rgba(0,0,0,0.3)';

    const maxWidth = this.canvas.width;
    const maxHeight = this.canvas.height;
    let middle = Math.floor(maxHeight / 2);

    const waveform = this.waveform;
    const maxVaue = Math.pow(2, waveform.bits) / 2 - 1;

    const stepY = middle / maxVaue;
    const stepX = maxWidth / this.waveform.data.length;

    let lastX = 0;
    let lastIndex = 0;

    const drawHalf = (
      ctx: CanvasRenderingContext2D,
      top: boolean,
      untilPercent?: number
    ) => {
      ctx.beginPath();
      ctx.moveTo(lastX, middle);

      const invert = top ? -1 : 1;

      while (lastIndex < this.waveform.data.length) {
        const x = lastIndex * stepX;
        const y =
          middle + Math.abs(this.waveform.data[lastIndex]) * stepY * invert;

        ctx.lineTo(x, y);
        if (
          (untilPercent !== undefined && x / maxWidth >= untilPercent) ||
          lastIndex === this.waveform.data.length - 1
        ) {
          ctx.lineTo(x, middle);
          ctx.closePath();
          ctx.fill();
          lastX = x;
          break;
        }
        lastIndex++;
      }
    };

    // Background
    ctx.fillStyle = secondaryColor;
    ctx.clearRect(0, 0, maxWidth, maxHeight);
    drawHalf(ctx, true);

    lastX = 0;
    lastIndex = 0;

    drawHalf(ctx, false);

    lastX = 0;
    lastIndex = 0;

    ctx = this.canvas.getContext('2d')!;
    ctx.clearRect(0, 0, maxWidth, maxHeight);
    // Foreground
    ctx.fillStyle = primaryColor;
    drawHalf(ctx, true);

    lastX = 0;
    lastIndex = 0;

    drawHalf(ctx, false);
  }

  mounted() {
    window.addEventListener('mousemove', this.dragMove, { passive: false });
    window.addEventListener('touchmove', this.dragMove, { passive: false });
    window.addEventListener('mouseup', this.dragStop, { passive: false });
    window.addEventListener('touchend', this.dragStop, { passive: false });
    window.addEventListener('resize', this.resizeCanvas);

    this.autoResize();
  }

  beforeDestroy() {
    window.removeEventListener('mousemove', this.dragMove);
    window.removeEventListener('mouseup', this.dragStop);
    window.removeEventListener('resize', this.resizeCanvas);

    this.resizeObserver?.disconnect();
  }
}
