


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { addPlaylist, getTracks, getPlaylist } from '@/service/api';
import { Playlist, ClassType, Track } from '@/service/types';
import { errorEventBus } from '../../../service';

@Component
export default class SavePlaylistDialog extends Vue {
  createDialog: boolean = false;
  createPlaylistName: string = '';
  createValid: boolean = false;
  createLoading: boolean = false;
  createDescription: string = '';

  @Prop()
  tracks!: Track[];

  async createPlaylist() {
    const valid = (this.$refs.createForm as any).validate();
    if (!valid) {
      return;
    }

    const tracks: string[] = this.tracks.map(t => t.id);

    this.createLoading = true;
    try {
      const res = await addPlaylist({
        name: this.createPlaylistName,
        description: this.createDescription ?? '',
        classTypes: [ClassType.Custom],
        tracks
      } as Playlist);

      this.$emit('created', res.playlist_id);

      this.resetCreateForm();
      this.createDialog = false;
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.createLoading = false;
    }
  }

  resetCreateForm() {
    (this.$refs.createForm as any).reset();
  }
}
