






























































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Player, getPlayer } from '../service/audio';
import { getModule } from 'vuex-module-decorators';
import { PlayerModule } from '../store/modules/player';
import { GuiModule } from '../store/modules/gui';

import WaveformCanvas from '@/components/WaveformCanvas.vue';
import { formatSeconds } from '../util';

@Component({
  components: {
    WaveformCanvas
  }
})
export default class PlayerControls extends Vue {
  voulmeStep: number = 0.05;

  get playerLoading() {
    const player = getModule(PlayerModule, this.$store);

    return player.loading;
  }

  get firstTrack(): boolean {
    const player = getModule(PlayerModule, this.$store);

    return player.currentTrackIndex === 0;
  }

  get lastTrack(): boolean {
    const player = getModule(PlayerModule, this.$store);

    return player.currentTrackIndex === player.tracks.length - 1;
  }

  get currentTrack() {
    const player = getModule(PlayerModule, this.$store);

    if (player.currentTrackIndex === null) {
      return null;
    }

    return player.tracks[player.currentTrackIndex];
  }

  get playing(): boolean {
    const gui = getModule(GuiModule, this.$store);

    return gui.playing;
  }

  get progressSeconds() {
    const player = getModule(PlayerModule, this.$store);

    if (!this.currentTrack) {
      return null;
    }

    return formatSeconds(player.position);
  }

  get totalSeconds() {
    return formatSeconds(this.currentTrack?.duration ?? 0);
  }

  get progressPercent(): number {
    const player = getModule(PlayerModule, this.$store);

    return player.position / (this.currentTrack?.duration ?? 1) ?? 1;
  }

  set progressPercent(percent: number) {
    const player = getModule(PlayerModule, this.$store);

    getPlayer().then(p => {
      p.seek = percent * (this.currentTrack?.duration ?? 0) ?? 0;
    });
  }

  get muted() {
    const playerStore = getModule(PlayerModule, this.$store);

    return playerStore.muted;
  }

  set muted(muted: boolean) {
    const playerStore = getModule(PlayerModule, this.$store);
    playerStore.setMuted(muted);

    if (muted) {
      getPlayer().then(p => (p.volume = 0));
    } else {
      getPlayer().then(p => (p.volume = playerStore.volume));
    }
  }

  get volume() {
    const playerStore = getModule(PlayerModule, this.$store);

    return playerStore.volume;
  }

  set volume(v: number) {
    const playerStore = getModule(PlayerModule, this.$store);
    playerStore.setVolume(v);

    if (v === 0) {
      this.muted = true;
    } else {
      this.muted = false;
    }
  }

  get trackCount() {
    const player = getModule(PlayerModule, this.$store);

    return player.tracks.length;
  }

  async previousTrack() {
    const player = await getPlayer();
    const playerStore = getModule(PlayerModule, this.$store);

    const shouldPlay = player.playing;

    if (playerStore.currentTrackIndex !== null) {
      playerStore.setcurrentTrackIndex(playerStore.currentTrackIndex - 1);
      await player.load(playerStore.tracks[playerStore.currentTrackIndex]);

      if (shouldPlay) {
        await player.play();
      }
    }
  }

  async nextTrack() {
    const player = await getPlayer();
    const playerStore = getModule(PlayerModule, this.$store);

    const shouldPlay = player.playing;

    if (playerStore.currentTrackIndex !== null) {
      playerStore.setcurrentTrackIndex(playerStore.currentTrackIndex + 1);
      await player.load(playerStore.tracks[playerStore.currentTrackIndex]);

      if (shouldPlay) {
        await player.play();
      }
    }
  }

  async play() {
    const playerStore = getModule(PlayerModule, this.$store);
    const player = await getPlayer();

    await player.play();
  }

  async pause() {
    const player = await getPlayer();
    await player.pause();
  }
}
