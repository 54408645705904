

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import PlayerControls from '@/components/PlayerControls.vue';
import TracksTable, { SearchOptions } from '@/components/TracksTable.vue';
import { getModule } from 'vuex-module-decorators';
import { PlayerModule } from '@/store/modules/player';
import SavePlaylistDialog from './SavePlaylistDialog.vue';
import { formatSeconds } from '../../../util';

@Component({
  components: {
    PlayerControls,
    TracksTable,
    SavePlaylistDialog
  }
})
export default class Player extends Vue {
  get tracks() {
    const player = getModule(PlayerModule, this.$store);

    return player.tracks;
  }

  get playlist() {
    const player = getModule(PlayerModule, this.$store);

    return player.playlist;
  }

  get totalDuration(): string | undefined {
    if (!this.tracks) {
      return;
    }

    return formatSeconds(
      this.tracks?.reduce((total, t) => total + (t.duration ?? 0), 0)
    );
  }
}
