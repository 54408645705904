var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-shrink-1 flex-grow-1 pa-0",style:({
    position: 'relative',
    height: '100%',
    width: '100%',
    pointerEvents: _vm.disabled ? 'none' : undefined,
    opacity: _vm.disabled ? '0.2' : undefined,
    cursor: _vm.dragging ? 'grabbing' : 'pointer'
  }),on:{"mousedown":_vm.dragStart,"touchstart":_vm.dragStart}},[_c('canvas',{ref:"backgroundCanvas",style:({
      position: 'absolute',
      left: '0',
      top: '0',
      height: '100%',
      width: '100%'
    })}),_c('canvas',{ref:"canvas",style:({
      position: 'absolute',
      left: '0',
      top: '0',
      height: '100%',
      width: '100%',
      '-webkit-clip-path': ("polygon(0 0, " + (_vm.showPercent *
        100) + "% 0, " + (_vm.showPercent * 100) + "% 100%, 0% 100%)"),
      clipPath: ("polygon(0 0, " + (_vm.showPercent * 100) + "% 0, " + (_vm.showPercent *
        100) + "% 100%, 0% 100%)")
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }