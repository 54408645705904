var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height flex-column flex-nowrap",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"flex-grow-0",style:({
      width: '100%'
    }),attrs:{"color":"transparent","flat":""}},[(_vm.playlist)?_c('v-toolbar-title',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.playlist.name)+" ")]):_c('v-toolbar-title',{staticClass:"mr-2",style:({
        userSelect: 'none',
        opacity: '0.6'
      })},[_vm._v(" "+_vm._s(_vm.$t('app.player.untitled'))+" ")]),(_vm.tracks && _vm.tracks.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticClass:"mr-4",style:({
            cursor: 'default'
          })},on),[_vm._v(" ("+_vm._s(_vm.totalDuration)+") ")])]}}],null,false,3311388341)},[_vm._v(" "+_vm._s(_vm.$t('app.playlists.duration'))+" ")]):_vm._e()],1),_c('v-container',{staticClass:"fill-height align-center justify-center mb-2",attrs:{"fluid":""}},[_c('TracksTable',{attrs:{"tracks":_vm.tracks,"player":true,"uniqueTracks":false}})],1),_c('PlayerControls',{staticClass:"elevation-4",style:({ height: _vm.$vuetify.breakpoint.mdAndUp ? '20%' : '60%' })})],1)}
var staticRenderFns = []

export { render, staticRenderFns }