var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.mdAndUp)?_c('v-container',{staticClass:"fill-height flex-column flex-nowrap align-center justify-center",class:{
    'cc-dark-blue': _vm.$vuetify.theme.dark
  },attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center justify-center flex-nowrap pa-4",style:({ height: '100%', width: '100%', overflow: 'hidden' }),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row fill-height align-center",attrs:{"cols":2}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.firstTrack || _vm.playerLoading || !_vm.currentTrack,"icon":"","height":"3rem","width":"3rem"},on:{"click":_vm.previousTrack}},on),[_c('v-icon',{attrs:{"size":"2rem"}},[_vm._v("mdi-skip-previous")])],1)]}}],null,false,4085227352)},[_vm._v(" "+_vm._s(_vm.$t('app.player.previous'))+" ")]),_c('v-spacer'),(!_vm.playing)?_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.currentTrack,"loading":_vm.playerLoading,"icon":"","height":"4rem","width":"4rem"},on:{"click":_vm.play}},on),[_c('v-icon',{attrs:{"size":"3rem"}},[_vm._v("mdi-play")])],1)]}}],null,false,543487935)},[_vm._v(" "+_vm._s(_vm.$t('app.player.play'))+" ")]):_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.playerLoading,"disabled":!_vm.currentTrack,"icon":"","height":"4rem","width":"4rem"},on:{"click":_vm.pause}},on),[_c('v-icon',{attrs:{"size":"3rem"}},[_vm._v("mdi-pause")])],1)]}}],null,false,3296552479)},[_vm._v(" "+_vm._s(_vm.$t('app.player.pause'))+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.lastTrack || _vm.playerLoading || !_vm.currentTrack,"icon":"","height":"3rem","width":"3rem"},on:{"click":_vm.nextTrack}},on),[_c('v-icon',{attrs:{"size":"2rem"}},[_vm._v("mdi-skip-next")])],1)]}}],null,false,967121672)},[_vm._v(" "+_vm._s(_vm.$t('app.player.next'))+" ")]),_c('v-spacer')],1),_c('v-col',{staticClass:"flex-shrink-0",attrs:{"cols":2}},[(_vm.currentTrack)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.currentTrack.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.currentTrack.artist))])],1)],1):_vm._e()],1),_c('v-col',{staticClass:"d-flex flex-column flex-shrink-1",style:({
        height: '100%'
      })},[_c('v-spacer'),_c('v-row',{staticClass:"flex-grow-0",style:({
          height: '50%',
          width: '100%'
        }),attrs:{"no-gutters":""}},[_c('WaveformCanvas',{attrs:{"disabled":!_vm.currentTrack,"percent":_vm.progressPercent},on:{"update:percent":function($event){_vm.progressPercent=$event}}})],1),_c('v-spacer'),_c('v-row',{staticClass:"flex-grow-0 ",style:({
          height: '20%',
          width: '100%'
        }),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row align-center flex-nowrap",attrs:{"cols":9}},[(_vm.currentTrack)?_c('div',[_vm._v(" "+_vm._s((_vm.progressSeconds + " / " + _vm.totalSeconds))+" ")]):_vm._e(),_c('v-spacer')],1),_c('v-col',{staticClass:"d-flex flex-row align-center flex-nowrap",attrs:{"cols":3}},[(!_vm.muted)?_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.muted = !_vm.muted}}},on),[_c('v-icon',[_vm._v("mdi-volume-high")])],1)]}}],null,false,2582083224)},[_vm._v(" "+_vm._s(_vm.$t('app.player.mute'))+" ")]):_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.muted = !_vm.muted}}},on),[_c('v-icon',[_vm._v("mdi-volume-off")])],1)]}}],null,false,2942238361)},[_vm._v(" "+_vm._s(_vm.$t('app.player.unmute'))+" ")]),_c('v-slider',{attrs:{"min":0,"max":1,"step":0,"thumb-label":true,"dense":"","hide-details":""},scopedSlots:_vm._u([{key:"thumb-label",fn:function(props){return [_vm._v(" "+_vm._s(Math.floor(props.value * 100))+"% ")]}}],null,false,1499553933),model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}})],1)],1),_c('v-spacer')],1)],1)],1):_c('v-container',{staticClass:"fill-height flex-column flex-nowrap align-center justify-center",class:{
    'cc-dark-blue': _vm.$vuetify.theme.dark
  },attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-column align-center justify-center px-4 flex-shrink-1",style:({
      position: 'relative',
      width: '100%',
      height: '10%'
    })},[(_vm.currentTrack)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.currentTrack.artist)+" - "+_vm._s(_vm.currentTrack.title))])],1)],1):_vm._e()],1),_c('v-row',{staticClass:"d-flex flex-column px-4 flex-shrink-1",style:({
      position: 'relative',
      width: '100%',
      maxHeight: '20%',
      height: '20%'
    })},[_c('WaveformCanvas',{attrs:{"disabled":!_vm.currentTrack,"percent":_vm.progressPercent},on:{"update:percent":function($event){_vm.progressPercent=$event}}}),(_vm.currentTrack)?_c('div',{style:({
        position: 'absolute',
        left: '50%',
        top: '50%',
        padding: '0.4rem',
        borderRadius: '1rem',
        backgroundColor: _vm.$vuetify.theme.dark
          ? 'rgba(0,0,0,0.1)'
          : 'rgba(255,255,255,0.3)',
        pointerEvents: 'none',
        transform: 'translate(-50%, -50%)'
      })},[_vm._v(" "+_vm._s((_vm.progressSeconds + " / " + _vm.totalSeconds))+" ")]):_vm._e()],1),_c('v-row',{staticClass:"align-center justify-center flex-nowrap pa-4",style:({
      height: '20%',
      width: '100%'
    }),attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.firstTrack || _vm.playerLoading || !_vm.currentTrack,"icon":"","height":"3rem","width":"3rem"},on:{"click":_vm.previousTrack}},on),[_c('v-icon',{attrs:{"size":"2rem"}},[_vm._v("mdi-skip-previous")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.player.previous'))+" ")]),_c('v-spacer'),(!_vm.playing)?_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.currentTrack,"loading":_vm.playerLoading,"icon":"","height":"4rem","width":"4rem"},on:{"click":_vm.play}},on),[_c('v-icon',{attrs:{"size":"3rem"}},[_vm._v("mdi-play")])],1)]}}],null,false,543487935)},[_vm._v(" "+_vm._s(_vm.$t('app.player.play'))+" ")]):_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.playerLoading,"disabled":!_vm.currentTrack,"icon":"","height":"4rem","width":"4rem"},on:{"click":_vm.pause}},on),[_c('v-icon',{attrs:{"size":"3rem"}},[_vm._v("mdi-pause")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.player.pause'))+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.lastTrack || _vm.playerLoading || !_vm.currentTrack,"icon":"","height":"3rem","width":"3rem"},on:{"click":_vm.nextTrack}},on),[_c('v-icon',{attrs:{"size":"2rem"}},[_vm._v("mdi-skip-next")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.player.next'))+" ")]),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }